// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-onde-ficar-js": () => import("./../../../src/pages/onde-ficar.js" /* webpackChunkName: "component---src-pages-onde-ficar-js" */),
  "component---src-pages-praias-js": () => import("./../../../src/pages/praias.js" /* webpackChunkName: "component---src-pages-praias-js" */),
  "component---src-pages-trilhas-js": () => import("./../../../src/pages/trilhas.js" /* webpackChunkName: "component---src-pages-trilhas-js" */),
  "component---src-templates-praia-js": () => import("./../../../src/templates/praia.js" /* webpackChunkName: "component---src-templates-praia-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

